<template>
    <div class="col-12 row my-1 language-select">
        <div class="col-lg-4 col-md-4 col-sm-4 my-2 text-small text-right">{{ $t("profile.lang") }}</div>
        <div class="col-lg-8 col-md-8 col-sm-8">
            <b-form-select 
                v-model="selected" 
                :options="options" 
                @change="setLocale(selected)"
                :value="value"
                @input="$emit('input', $event)"
            />
        </div>
    </div>   
</template>

<script>
import { getLocales } from "@/utils/getLocales.js";

export default {
    props: ['value','lang'],
    data() { return{
        selected: '',
        options: getLocales()
    }},
    mounted() {
        this.selected = this.$i18n.locale;
    }

}
</script>