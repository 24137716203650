<template>
     <div class="row mx-auto justify-content-center">
        <div class="col-lg-5">

            <h2 class="text-primary mb-4">{{ $t('site.title') }} – {{ $t('profile.signup') }}</h2>

            <InputGen :fields="profile" v-model="user" />

             <b-row class="my-4">
                <b-col sm="12" class="text-center">
                    <!-- signup button -->
                    <b-button 
                        variant="primary px-3 mx-1 mb-1"
                        @click="signup()">
                        {{ $t('profile.signup') }}
                    </b-button>
                    <!-- login button -->
                    <b-button 
                        variant="outline-primary px-3 mx-1" 
                        @click="$router.push({name: 'Login'})">
                        {{ $t('profile.log_in') }}
                    </b-button>

                </b-col>
            </b-row>

        </div>
    </div>
</template>
<script>
import InputGen from "@/views/ProfileComponents/InputGen.vue";
export default {    
    components: { InputGen },
    data() {return{
        user: {},
        profile: [
            { name: "lang" }, 
            { name: "hr" }, 
            { name: "name", label: "profile.full_name", required: "true" },
            { name: "email", required: true }, 
            { name: "password", type: "password", placeholder: "error.password", required: true }, 
            { name: "password_confirmation", label: "profile.password_repeat", type: "password", required: true },
            { name: "hr" },
            { name: "birth", type: "date" }, 
            { name: "country" }, 
            { name: "city" }, 
            { name: "phone" },
            { name: "website", type: "url" },
        ],

        }
    },
    methods: {
        async signup() {
            if(this.user.password )
            var res = await this.axon('post','signup',this.user);
            if(res=='success.registered') {
                this.commit('alert',this.$t('success.registered'));
                this.$router.push({name: 'Login'}); }
        }
    }
}
</script>