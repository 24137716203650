<template>
<div>   
    <!-- start loop -->
    <div v-for="(field, index) in fields" :key="index">
        
        <!-- if hr line -->
        <hr v-if="field.name=='hr'" />

        <!-- if language box -->
        <SetLanguage v-else-if="field.name=='lang'" 
            @input="emit(field.name,$event)" />

        <!-- else field -->
        <b-row v-else class="my-2 text-muted" >

            <b-col sm="4" class="text-right">
                <label>
                    {{ field.label ? $t(field.label) : $t('profile.'+field.name) }} 
                    <span v-if="field.required" class="text-danger">*</span>
                </label>
            </b-col>

            <b-col sm="8" class="text-left">
                
                <!-- if date -->
                <!-- <b-form-datepicker 
                    v-if="field.type=='date'" 
                    :locale="$i18n.locale" 
                    :disabled="field.disabled ? true : false" 
                    :required="field.required ? true : false" 
                    :value="value[field.name]"
                    @input="emit(field.name,$event)" 
                /> -->

                <!-- others -->
                <b-form-input  
                    
                    :type="field.type ? field.type : 'text'" 
                    :required="field.required ? true : false" 
                    :disabled="field.disabled ? true : false" 
                    :value="value[field.name]"
                    :placeholder="field.placeholder ? $t(field.placeholder) : null"
                    @input="emit(field.name,$event)" 
                />
                
            </b-col>

        </b-row>

    </div>
</div>
</template>
<script>
import SetLanguage from "@/views/BaseComponents/SetLanguage.vue";
export default {
    components: { SetLanguage },
    props: ['value','fields'],
    methods: {
        emit(name,val) {
            this.value[name] = val;
        },
    }
}
</script>


