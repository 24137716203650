import supportedLocales from "@/config/locales"

export function getLocales() {
  let annotatedLocales = []

  for (const value of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      value,
      text: supportedLocales[value]
    })
  }

  return annotatedLocales
}